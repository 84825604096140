import { Layout } from "antd";
import { graphql, Link, useStaticQuery } from "gatsby";
import styles from "./style.module.scss";
import classnames from "classnames";
import SEO from "components/seo";
import React, { FC, useMemo } from "react";
import routes from "routes";

interface LoginLayoutProps {
  title?: string;
}

type Props = LoginLayoutProps;

const LoginLayout: FC<Props> = ({ title, children }) => {
  const {
    logo: {
      childImageSharp: { original: logo },
    },
    backgrounds: { nodes: backgrounds },
  } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "ph-logo.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      backgrounds: allFile(filter: { relativePath: { regex: "/photos/" } }) {
        nodes {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  `);

  const background = useMemo(
    () =>
      backgrounds.length > 0
        ? backgrounds[Math.floor(Math.random() * backgrounds.length)]
            .childImageSharp.original
        : null,
    [backgrounds]
  );
  const backgroundEnabled = Boolean(background);

  return (
    <Layout>
      <SEO title={title} />
      <Layout.Content>
        <div
          className={classnames(styles.layout, {
            [styles.light]: backgroundEnabled,
          })}
          style={{
            backgroundImage: backgroundEnabled
              ? `url('${background.src}')`
              : `none`,
          }}
        >
          <div className={styles.header}>
            <div className={styles.logo}>
              <Link to={routes.home}>
                <img src={logo.src} alt="Покровский хлеб" />
              </Link>
            </div>
          </div>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.footer} text-center`}>
            <p>
              &copy; {new Date().getFullYear()} Покровский хлеб. Все права
              защищены.
            </p>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default LoginLayout;
