import { useApolloClient } from "@apollo/react-hooks";
import { Button, Checkbox, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { Link, navigate } from "gatsby";
import styles from "./style.module.scss";
import { useSetAuthenticationInfo } from "apollo/local";
import LoginLayout from "components/layouts/login";
import React, {
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import routes from "routes";
import { useErrorHandler } from "utils";
import { useOAuthClient } from "components/oauth";

interface AuthenticateModel {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient: boolean;
}

type Props = FormComponentProps<AuthenticateModel>;

const Login: FC<Props> = ({ form }) => {
  const client = useApolloClient();
  const [oauthError, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    client.clearStore();
  }, [client]);

  const oauthClient = useOAuthClient();
  const [setAuthInfo, { error: setAuthError }] = useSetAuthenticationInfo();
  useErrorHandler([oauthError, setAuthError]);

  const validationCallback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (
      validationErrors: any,
      { userNameOrEmailAddress, password }: AuthenticateModel
    ) => {
      if (validationErrors) {
        return;
      }

      setLoading(true);

      try {
        const response = await oauthClient.login(
          userNameOrEmailAddress,
          password
        );

        if (response.status === 200) {
          const {
            access_token,
            token_type,
            expires_in,
            refresh_token,
          } = await response.json();

          await setAuthInfo({
            variables: {
              accessToken: access_token,
              expireInSeconds: expires_in,
              refreshToken: refresh_token,
              tokenType: token_type,
            },
          });

          navigate(routes.home);
        } else {
          const { error_description } = await response.json();

          setError(new Error(error_description));
        }
      } finally {
        setLoading(false);
      }
    },
    [oauthClient, setAuthInfo]
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    e => {
      if (e) {
        e.preventDefault();
      }

      form.validateFields((err, model) => {
        validationCallback(err, model);
      });
    },
    [form, validationCallback]
  );

  const { getFieldDecorator } = form;

  return (
    <LoginLayout title="Вход">
      <div className={styles.block}>
        <div className="row">
          <div className="col-xl-12">
            <div className={styles.inner}>
              <div className={styles.form}>
                <h4 className="text-uppercase">
                  <strong>Пожалуйста войдите</strong>
                </h4>
                <br />
                <Form
                  hideRequiredMark={true}
                  layout="vertical"
                  onSubmit={handleSubmit}
                >
                  <Form.Item label="Логин или электронная почта">
                    {getFieldDecorator<AuthenticateModel>(
                      "userNameOrEmailAddress",
                      {
                        initialValue: "",
                        rules: [
                          {
                            required: true,
                            message: "Введите ваш логин или электронную почту",
                          },
                        ],
                      }
                    )(<Input size="default" disabled={loading} />)}
                  </Form.Item>
                  <Form.Item label="Пароль">
                    {getFieldDecorator<AuthenticateModel>("password", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Введите ваш пароль",
                        },
                      ],
                    })(
                      <Input
                        disabled={loading}
                        size="default"
                        type="password"
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator<AuthenticateModel>("rememberClient", {
                      valuePropName: "checked",
                      initialValue: true,
                    })(<Checkbox disabled={loading}>Запомнить меня</Checkbox>)}
                  </Form.Item>
                  <div className="form-actions">
                    <Button
                      type="primary"
                      className="width-150 mr-4"
                      htmlType="submit"
                      loading={loading}
                    >
                      Вход
                    </Button>
                    <Link
                      to={routes.forgot}
                      className="utils__link--blue utils__link--underlined pull-right"
                    >
                      Забыли пароль?
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Form.create()(Login);
